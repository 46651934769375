<template>
  <!-- 注册页 -->
  <div class="register-page">
    <div class="title">
      <h1>Hasta S/ 2000</h1>
      <div class="des">
        <div>
          <span></span>
          <p>1 minuto para rellenar sus datos</p>
        </div>
        <div>
          <span></span>
          <p>aprobar en 1 minuto</p>
        </div>
        <div>
          <span></span>
          <p>pagar en 5 minutos</p>
        </div>
      </div>
    </div>

    <van-form @failed="onFailed" ref="form">
      <div class="inp">
        <span>+51</span>
        <van-field
          maxlength="9"
          v-model="registerForm.mobile"
          type="number"
          name="mobile"
          placeholder="Por favor, introduzca su número de teléfono móvil"
          :rules="[
            { required: true, trigger: 'onBlur' },
            { validator, trigger: 'onBlur' },
          ]"
        />
      </div>

      <p>Example:912345678 (9 digits)</p>
      <div class="send">
        <van-field
          maxlength="4"
          v-model="registerForm.code"
          center
          clearable
          type="number"
          placeholder="Código de verificación de 4 dígitos"
          :rules="[
            { required: true, trigger: 'onBlur' },
            { pattern: /^[0-9]{4}$/, trigger: 'onBlur' },
          ]"
        >
          <!-- <template #button>
            <van-button size="small" type="primary">发送验证码</van-button>
          </template> -->
        </van-field>
        <van-button
          ref="btn"
          size="large"
          type="primary"
          @click="handelBtnClick"
          :disabled="btnDisabled"
          :text="getCodeText"
          :class="{ line_heigth: lineHeigth }"
        ></van-button>
      </div>
      <div class="submit">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="handelSub"
          >Ver la cuota no auditoría</van-button
        >
      </div>
    </van-form>
    <div class="content">
      <div class="sub_title">
        <span></span>
        <h3>Por qué elegir Fondos Ricos</h3>
        <span></span>
      </div>
      <div class="main">
        <div class="item">
          <img src="@/assets/images/icon_interest@2x.png" alt="" />
          <div class="info">
            <h5>Tipo de interés bajo</h5>
            <p>Tipos de interés anualizados mínimo 6%</p>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/icon_operation@2x.png" alt="" />
          <div class="info">
            <h5>Fácil de manejar</h5>
            <p>Aplicación rápida en 4 pasos</p>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/icon_fast@2x.png" alt="" />
          <div class="info">
            <h5>Pago rápido</h5>
            <p>Se llega en 15 minutos a su cuenta</p>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/images/icon_high@2x.png" alt="" />
          <div class="info">
            <h5>Límite de crédito elevado</h5>
            <p>Préstamo de hasta S/ 2000</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceDetector from "device-detector-js";
import { getLoginCode, drainageLogin } from "@/api/user.js";
import { setToken } from "@/utils/storage";
import { localData } from "@/utils/local";
export default {
  name: "register-page",
  data() {
    return {
      device: {},
      registerForm: {
        mobile: "",
        code: "",
        UpdateDate: "20221130",
      },
      btnDisabled: false,
      getCodeText: "Obtener el código de verificación",
      totalTime: 60,
      lineHeigth: false,
      appPackage: "", //包名
      appVersion: "", //版本号
      deciveBrand: "", //手机品牌
      deviceModel: "", //手机型号
    };
  },
  mounted() {
    // enter键提交表单
    this.keyDown();
  },
  destroyed() {
    //取消键盘监听事件
    document.onkeydown = null;
  },
  methods: {
    async handelBtnClick() {
      // 校验表单
      if (!this.validator(this.registerForm.mobile)) return;
      // 按钮禁用
      this.btnDisabled = true;
      try {
        // --------------获取验证码
        const res = await getLoginCode({
          languageCode: "CH",
          itemCode: "NUF",
          mobile: `51${this.registerForm.mobile}`,
          sendMethod: 1,
        });
        // console.log(777);
        console.log(res, "000");
        if (res.code === 1) {
          this.appPackage = res.data.appPackage;
          this.appVersion = res.data.appVersion;
          // 按钮倒计时
          this.lineHeigth = true;

          this.getCodeText = this.totalTime + "s";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.getCodeText = this.totalTime + "s";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              (this.getCodeText = "Obtener el código de verificación"),
                (this.totalTime = 60);
              this.btnDisabled = false;
              this.lineHeigth = false;
            }
          }, 1000);
        } else if (res.code === 0) {
          this.$toast.fail(res.message);
          this.btnDisabled = false;
        }
        // 获取手机品牌，手机型号
        const deviceDetector = new DeviceDetector();
        const userAgent = window.navigator.userAgent;
        let device = deviceDetector.parse(userAgent);
        console.log(device);
        this.device = device;
      } catch (e) {
        console.dir(e, 111);
      }
    },
    async handelSub() {
      // console.log(2222);
      // 校验表单
      await this.$refs.form.validate();
      console.log(333);
      // ---------------登录
      try {
        const res = await drainageLogin({
          languageCode: "ESP",
          itemCode: "NUF",
          mobile: `51${this.registerForm.mobile}`,
          loginCode: this.registerForm.code,
          loginVoiceCode: "",
          password: "",
          invitCode: `H5NUF${this.registerForm.UpdateDate}`, //注册渠道码
          appVersion: this.appVersion,
          appPackage: this.appPackage,
          deciveBrand: this.device.device.brand
            ? this.device.device.brand
            : "unCheck", //手机品牌
          deviceModel: this.device.device.model
            ? this.device.device.model
            : "unCheck", //手机型号
        });
        console.log(res, "登录");
        if (res.code === 1) {
          // 成功提示
          this.$toast.success(res.message);
          // 本地存操作凭证
          setToken(res.data.sessionId);
          // 本地存productItemCode
          localData("set", "productItemCode", res.data.productItemCode);
          localData("set", "SubimitInfo", res.data.drainageSubimitInfo);

          // 跳转页面
          this.$router.push("/submitInfo");
          localData("set", "mobile", this.registerForm.mobile);
        }
        if (res.code === 0) {
          this.$toast.fail(res.message);
        } else if (res.code === null) {
          this.$toast.fail("Código de verificación no válido");
        }
      } catch (e) {
        console.dir(e);
        this.$toast.fail("Código de verificación no válido");
      }
    },
    // enter键提交表单
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || window.event || arguments.callee.caller.arguments[0];
        // console.log("键盘按键==", e1, e1.keyCode);
        if (e1 && e1.keyCode === 13) {
          this.handelSub();
        }
      };
    },
    validator(val) {
      return /^9\d{8}$/.test(val);
    },
    // 没通过验证
    onFailed() {
      // console.log(111);
      if (!this.validator(this.registerForm.mobile)) {
        console.log(this.validator(this.registerForm.mobile), 111);
        this.$toast.fail(
          "Por favor, introduzca su número de teléfono que cumpla los criterios"
        );
      } else {
        console.log(111);
        this.$toast.fail("Código de verificación de 4 dígitos");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register-page {
  height: 1610px;
  background-image: url("~@/assets/images/background.png");
  background-size: 100% 100%;
  // height: 100px;
  // background-color: pink;
  .title {
    // width: 710px;
    padding-top: 84px;
    padding-left: 40px;
    // background-color: red;
    margin-bottom: 203px;
    h1 {
      // width: 388px;
      // background-color: blue;
      color: rgba(255, 235, 121, 1);
      font-size: 60px;
      font-family: Roboto-Bold;
      font-weight: 700;
      text-align: left;
      line-height: 71px;
      // margin: 0;
      // margin-right: 40px;
      // margin-top: 84px;
      margin-bottom: 12px;
      text-shadow: 0px 4px 14px rgba(18, 4, 255, 1);
    }
    .des {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 458px;
      height: 120px;
      font-family: Roboto-Regular, Roboto;
      // background-color: pink;
      div {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #ffec62;
          border-radius: 50%;
          margin-right: 11px;
          vertical-align: middle;
        }
        p {
          // width: 407px;
          font-size: 28px;
          font-family: Roboto-Regular, Roboto;
          // font-weight: 400;
          color: #ffffff;
          line-height: 1;
          // margin: 0;
          text-align: left;
        }
      }
    }
  }
  .van-form {
    padding: 0 30px;
    margin-bottom: 80px;
    .inp {
      position: relative;
      display: flex;
      span {
        // color: red;
        position: absolute;
        top: 28px;
        left: 59px;
        font-size: 24px;
        z-index: 999;
      }
      .van-cell {
        position: relative;
        color: #ffffff !important;

        flex: 1;
        height: 80px;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 10px;
        // width: 660px;
        // padding: 20px 55px 20px 24px;
        padding: 0;
        padding-left: 116px;
        font-size: 24px;
        line-height: 80px;
        // overflow: visible;
        overflow: hidden;
        // 设置placeholder字体颜色
        ::v-deep input.van-field__control::-webkit-input-placeholder {
          font-size: 24px;
          color: #999999;
          line-height: 33px;
        }
        ::v-deep .van-cell__value--alone {
          font-size: 24px !important;
        }
      }
      ::v-deep .van-cell.van-field--error {
        .van-field__control::placeholder,
        .van-field__control {
          font-size: 24px;
          color: #999999;
        }
      }
      .van-cell::before {
        content: "";
        position: absolute;
        top: 18px;
        left: 15px;
        width: 44px;
        height: 44px;
        background-image: url("~@/assets/images/login_icon_phone@2x.png");
        background-size: 100%, 100%;
        // background-color: red;
      }
    }
    p {
      font-size: 24px;
      color: #ffffff;
      text-align: left;
      line-height: 28px;
      margin-top: 16px;
      // margin-left: -115px;
      font-family: Roboto-Regular, Roboto;
      margin-bottom: 24px;
    }
    .send {
      display: flex;
      margin-bottom: 32px;
      .van-cell {
        position: relative;
        color: #ffffff !important;

        flex: 1;
        height: 80px;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 10px;
        // width: 660px;
        // padding: 20px 55px 20px 24px;
        padding: 0;
        padding-left: 74px;
        font-size: 24px;
        line-height: 80px;
        // overflow: visible;
        overflow: hidden;
        ::v-deep .van-button--large {
          height: 80px;
          width: 198px;
          border-radius: 10px;
        }
        // 设置placeholder字体颜色
        ::v-deep input.van-field__control::-webkit-input-placeholder {
          font-size: 24px;
          color: #999999;
          line-height: 33px;
        }
      }
      ::v-deep .van-cell.van-field--error {
        .van-field__control::placeholder,
        .van-field__control {
          font-size: 24px;
          color: #999999;
        }
      }
      .van-cell::before {
        content: "";
        position: absolute;
        top: 18px;
        left: 15px;
        width: 44px;
        height: 44px;
        background-image: url("~@/assets/images/login_icon_phone@2x(1).png");
        background-size: 100%, 100%;
        // background-color: red;
      }
      .van-button--primary {
        width: 210px;
        height: 80px;
        border-radius: 10px;
        // padding: 15px 12px;
        background: linear-gradient(180deg, #ffe689 0%, #ffbf47 100%);
        margin-left: 22px;
        border: none;
        .van-button__text {
          // width: 100%;
          height: 50px;
          font-size: 22px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #2922a8;
          line-height: 25px;
          // line-height: 50px;
          // background-color: pink;
        }
      }
      .van-button--primary.line_heigth {
        .van-button__text {
          line-height: 50px !important;
          // font-size: 30px;
        }
      }
    }
    .submit {
      ::v-deep .van-button {
        height: 80px;
        background: linear-gradient(180deg, #ffe689 0%, #ffbf47 100%);
        border-radius: 10px;
        .van-button__text {
          // width: 351px;
          // height: 38px;
          font-size: 32px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #2922a8;
          line-height: 38px;
        }
      }
      ::v-deep .van-button--info {
        border: none;
      }
    }
  }
  .content {
    height: 652px;

    margin: 0 30px;
    background: #ffffff;
    border-radius: 10px;
    // padding-top: 40px;
    padding: 40px 0px 34px 38px;
    .sub_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 42px;
      span {
        display: inline-block;
        width: 42px;
        height: 24px;
        // background-color: red;
      }
      span:nth-child(1) {
        background-image: url("~@/assets/images/decorate@2x(1).png");
        background-size: 100% 100%;
        // margin-left: 73px;
      }
      span:nth-child(3) {
        background-image: url("~@/assets/images/decorate@2x.png");
        background-size: 100% 100%;
        // margin-right: 73px;
      }
      h3 {
        // width: 660px;
        font-size: 34px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #2c72ff;
        line-height: 40px;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 496px;
      // background-color: red;
      .item {
        display: flex;
        // justify-content: space-between;
        height: 100px;
        // background-color: pink;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        img {
          width: 100px;
          height: 100px;
          margin-right: 30px;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          // width: 470px;
          h5 {
            // width: 265px;
            height: 35px;
            font-size: 30px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #333333;
            line-height: 35px;
          }
          p {
            // width: 470px;
            // height: 30px;
            font-size: 26px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 365px) {
  .send {
    ::v-deep .van-button--primary {
      .van-button__text {
        transform: scale(0.9) !important;
        font-family: Arial, Helvetica, sans-serif !important;
        line-height: 28px !important;
      }
    }
  }
  // ::v-deep .van-cell__value {
  //   input {
  //     transform: scale(0.917) !important;
  //   }
  // }
  .send {
    .van-cell {
      padding-left: 60px !important;
    }
  }
  .inp {
    .van-cell {
      padding-left: 110px !important;
    }
  }
}
</style>
