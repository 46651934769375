import Vue from "vue";
import {
  Checkbox,
  CheckboxGroup,
  Button,
  Tabbar,
  TabbarItem,
  NavBar,
  Form,
  Field,
  Toast,
  Dialog,
  Popup,
  Picker,
  // van-popup
} from "vant";
// import { Toast } from 'vant';

Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Dialog);
