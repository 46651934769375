const key = "token_L1";
export const setToken = (newToken) => {
  return localStorage.setItem(key, newToken);
};

export const getToken = () => {
  return localStorage.getItem(key);
};

export const delToken = () => {
  return localStorage.removeItem(key);
};
