import request from "@/utils/request";

export const getLoginCode = (data) => {
  // return request.post("/app/getLoginCode", data);
  return request.post(
    `/app/getLoginCode?itemCode=${data.itemCode}&languageCode=${data.languageCode}&mobile=${data.mobile}&sendMethod=${data.sendMethod}`,
    data
  );
};

export const drainageLogin = (data) => {
  // return request.post("/app/drainageLogin", data);
  return request.post(
    `/app/drainageLogin?itemCode=${data.itemCode}&languageCode=${data.languageCode}&mobile=${data.mobile}&loginCode=${data.loginCode}&invitCode=${data.invitCode}&appVersion=${data.appVersion}&appPackage=${data.appPackage}&deciveBrand=${data.deciveBrand}&deviceModel=${data.deviceModel}`,
    data
  );
};

export const SubimitInfo = (data) => {
  // return request.post("/app/drainageSubimitInfo", data);
  return request.post(
    `/app/drainageSubimitInfo?itemCode=${data.itemCode}&languageCode=${data.languageCode}&sessionId=${data.sessionId}&companyMonthIncome=${data.companyMonthIncome}&educationLevel=${data.educationLevel}&incomeSourceType=${data.incomeSourceType}&marryStatus=${data.marryStatus}&workNature=${data.workNature}&childrenTotal=${data.childrenTotal}&productItemCode=${data.productItemCode}`,
    data
  );
};

export const getOptionsShowList = (data) => {
  return request.post(
    `/app/getOptionsShowList?itemCode=${data.itemCode}&languageCode=${data.languageCode}`,
    data
  );
};
export const hiddenSubmit = (data) => {
  // return request.post("/app/drainageHiddenSubmit", data);
  return request.post(
    `/app/drainageHiddenSubmit?itemCode=${data.itemCode}&languageCode=${data.languageCode}&sessionId=${data.sessionId}`,
    data
  );
};
