<template>
  <div class="login-page">
    <div class="title">
      <h1>Hasta S/ 2000</h1>
      <div class="des">
        <div>
          <span></span>
          <p>1 minuto para rellenar sus datos</p>
        </div>
        <div>
          <span></span>
          <p>aprobar en 1 minuto</p>
        </div>
        <div>
          <span></span>
          <p>pagar en 5 minutos</p>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="top">
        <!-- <img src="" alt="" /> -->
        <span></span>
        <h4>51{{ this.mobile }}</h4>
      </div>
      <!-- 表单 -->
      <van-form ref="infoForm" @failed="onFailed_info">
        <div class="main">
          <!-- 教育程度 -->
          <div class="item">
            <van-field
              readonly
              clickable
              name="Nivel educativo"
              :value="value.value_edu.value"
              label="Nivel educativo"
              placeholder="Por favor elige"
              @click="show.showPicker_edu = true"
              :rules="[{ required: true, trigger: 'onChange' }]"
            />
            <van-popup v-model="show.showPicker_edu" position="bottom" round>
              <van-picker
                confirm-button-text="Confirmar"
                cancel-button-text="Cancelar"
                show-toolbar
                :columns="columns.columns_edu"
                @confirm="onConfirm_edu"
                @cancel="show.showPicker_edu = false"
              />
            </van-popup>
          </div>
          <!-- 婚姻状况 -->
          <div class="item">
            <van-field
              readonly
              clickable
              name="Estado civil"
              :value="value.value_marray.value"
              label="Estado civil"
              placeholder="Por favor elige"
              @click="show.showPicker_marray = true"
              :rules="[{ required: true, trigger: 'onChange' }]"
            />
            <van-popup v-model="show.showPicker_marray" position="bottom" round>
              <van-picker
                confirm-button-text="Confirmar"
                cancel-button-text="Cancelar"
                show-toolbar
                :columns="columns.columns_marray"
                @confirm="onConfirm_marray"
                @cancel="show.showPicker_marray = false"
              />
            </van-popup>
          </div>
          <!-- 孩子数量 -->
          <div class="item">
            <van-field
              readonly
              clickable
              name="Numero de hijos"
              :value="value.value_childrens.value"
              label="Numero de hijos"
              placeholder="Por favor elige"
              @click="show.showPicker_childrens = true"
              :rules="[{ required: true, trigger: 'onChange' }]"
            />
            <van-popup
              v-model="show.showPicker_childrens"
              position="bottom"
              round
            >
              <van-picker
                confirm-button-text="Confirmar"
                cancel-button-text="Cancelar"
                show-toolbar
                :columns="columns.columns_childrens"
                @confirm="onConfirm_childrens"
                @cancel="show.showPicker_childrens = false"
              />
            </van-popup>
          </div>
          <!-- 工作类型 -->
          <div class="item">
            <van-field
              readonly
              clickable
              name="Tipo de empleo"
              :value="value.value_work.value"
              label="Tipo de empleo"
              placeholder="Por favor elige"
              @click="show.showPicker_work = true"
              :rules="[{ required: true, trigger: 'onChange' }]"
            />
            <van-popup v-model="show.showPicker_work" position="bottom" round>
              <van-picker
                confirm-button-text="Confirmar"
                cancel-button-text="Cancelar"
                show-toolbar
                :columns="columns.columns_work"
                @confirm="onConfirm_work"
                @cancel="show.showPicker_work = false"
              />
            </van-popup>
          </div>
          <!-- 月收入 -->
          <div class="item_inp">
            <!-- <p>Ingresos mensuales</p> -->
            <van-field
              maxlength="6"
              type="number"
              name="Rango salarial"
              v-model="income"
              label="Ingresos mensuales"
              placeholder="Por favor complete"
              :rules="[{ required: true }]"
            />
          </div>
          <!-- 收入来源 -->
          <div class="item">
            <van-field
              readonly
              clickable
              name="Fuente de ingresos"
              :value="value.value_source.value"
              label="Fuente de ingresos"
              placeholder="Por favor elige"
              @click="show.showPicker_source = true"
              :rules="[{ required: true, trigger: 'onChange' }]"
            />
            <van-popup v-model="show.showPicker_source" position="bottom" round>
              <van-picker
                confirm-button-text="Confirmar"
                cancel-button-text="Cancelar"
                show-toolbar
                :columns="columns.columns_source"
                @confirm="onConfirm_source"
                @cancel="show.showPicker_source = false"
              />
            </van-popup>
          </div>
        </div>
        <div class="submit">
          <van-button
            block
            type="info"
            native-type="submit"
            @click="handelSubmit"
            >Evaluar el límite real</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- 弹框 -->
    <van-dialog v-model="showDialog" :show-confirm-button="false">
      <img src="@/assets/write/pop_picture@2x.png" />
      <p>
        Felicitaciones, usted ha conseguido el derecho de no auditoría, ahora ir
        a descargar la aplicación obtendrá una cuota de hasta S / 1900, el 99%
        de pase (válido dentro de las 24 horas)
      </p>
      <button @click="handelDownload">Descargar App</button>
      <div class="wait" @click="handelCloseDia">Espera</div>
    </van-dialog>
  </div>
</template>

<script>
import { SubimitInfo, getOptionsShowList, hiddenSubmit } from "@/api/user.js";
import { getToken } from "@/utils/storage";
import { localData } from "@/utils/local";

export default {
  name: "login-page",
  data() {
    return {
      mobile: "",
      sessionId: getToken(),
      subimitInfo: "",
      showDialog: false,
      show: {
        showPicker_edu: false,
        showPicker_marray: false,
        showPicker_childrens: false,
        showPicker_work: false,
        showPicker_source: false,
      },
      arr_noPass: [],
      value: {
        value_edu: {},
        value_marray: {},
        value_childrens: {},
        value_work: {},
        value_source: {},
      },
      income: "",
      list: {},
      list_value: {},
      list_index: {},
      listObj: {},
      columns: {
        columns_edu: [],
        columns_marray: [],
        columns_childrens: [],
        columns_work: [],
        columns_source: [],
      },
      indexs: {
        index_edu: [],
        index_marray: [],
        index_childrens: [],
        index_work: [],
        index_source: [],
      },
    };
  },
  created() {
    console.log(getToken(), "TOKEN");
    this.mobile = localData("get", "mobile");
    this.getOptionsShowList();
  },
  mounted() {
    // enter键提交表单
    this.keyDown();
  },
  destroyed() {
    //取消键盘监听事件
    document.onkeydown = null;
  },
  methods: {
    // 获取表单枚举
    async getOptionsShowList() {
      const { data: res } = await getOptionsShowList({
        itemCode: "NUF",
        languageCode: "ESP",
        // connetColumn: "",
      });
      // debugger;
      console.log(res, "获取枚举1111");
      this.list = {
        columns_edu: res.educationLevel,
        columns_marray: res.marryStatus,
        columns_childrens: res.childNum,
        columns_work: res.workNature,
        columns_source: res.incomeSourceType,
      };
      this.list_value = {
        value_edu: res.educationLevel,
        value_marray: res.marryStatus,
        value_childrens: res.childNum,
        value_work: res.workNature,
        value_source: res.incomeSourceType,
      };
      this.list_index = {
        index_edu: res.educationLevel,
        index_marray: res.marryStatus,
        index_childrens: res.childNum,
        index_work: res.workNature,
        index_source: res.incomeSourceType,
      };
      this.listObj = {
        value_edu: "educationLevel",
        value_marray: "marryStatus",
        value_childrens: "childrenTotal",
        value_work: "workNature",
        value_source: "incomeSourceType",
      };
      for (let key in this.columns) {
        // console.log(key, "key");
        this.columns[key] = this.list[key].map((item) => {
          return item.showContent;
        });
      }
      console.log(this.columns, "this.columns");
      for (let k in this.indexs) {
        // console.log(k, "k");
        // console.log(this.indexs[k], "item");
        // console.log(this.list_index[k], "list");
        this.indexs[k] = this.list_index[k].map((i) => {
          // console.log(i.dataValue);
          return i.dataValue;
        });
      }
      // console.log(this.indexs, "this.index");

      const result = localData("get", "SubimitInfo");
      // 用arr记录空值
      const arr = [];
      for (let key in result) {
        // console.log(key);
        if (result[key] === "") {
          arr.push(key);
        }
      }
      console.log(arr, "arr");
      if (arr.length === 0) {
        // 说明没有空值，此时将信息渲染到页面
        this.subimitInfo = localData("get", "SubimitInfo");
        console.log(this.value, "this.value");
        for (let key in this.value) {
          // console.log(
          //   key,
          //   "key",
          //   this.list_value[key],
          //   this.listObj[key],
          //   this.subimitInfo[this.listObj[key]]
          // );
          let res_submit = this.list_value[key].find((item) => {
            return item.dataValue == this.subimitInfo[this.listObj[key]];
          });
          console.log(res_submit, "res_submit");
          this.value[key] = {
            value: res_submit.showContent,
            datavalue: res_submit.dataValue,
          };
        }
        this.income = this.subimitInfo.companyMonthIncome;
      }
    },
    async handelSubmit() {
      try {
        // 表单校验;
        await this.$refs.infoForm.validate();
        // --------提交认证信息
        const res = await SubimitInfo({
          languageCode: "ESP",
          itemCode: "NUF",
          sessionId: this.sessionId,
          companyMonthIncome: this.income,
          educationLevel: this.value.value_edu.datavalue,
          incomeSourceType: this.value.value_source.datavalue,
          marryStatus: this.value.value_marray.datavalue,
          workNature: this.value.value_work.datavalue,
          childrenTotal: this.value.value_childrens.datavalue,
          productItemCode: localData("get", "productItemCode"),
        });
        console.log(res, "提交认证信息");
        // 打开成功弹框
        this.showDialog = true;
      } catch (e) {
        console.dir(e);
        this.arr_noPass = e;
        console.log(this.arr_noPass, "this.arr_noPass");
      }
    },
    // enter键提交表单
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || window.event || arguments.callee.caller.arguments[0];
        // console.log("键盘按键==", e1, e1.keyCode);
        if (e1 && e1.keyCode === 13) {
          this.handelSubmit();
        }
      };
    },

    // 信息填写校验提示
    onFailed_info() {
      const result = this.arr_noPass[0].name;
      this.$toast.fail(
        `Por favor, añada 【${result}】para una evaluación más precisa de su cuota de no auditoría`
      );
    },
    onConfirm_edu(value, index) {
      // console.log(value, "value", index);
      this.value.value_edu = {
        value,
        datavalue: this.indexs.index_edu[index],
      };
      console.log(this.value.value_edu, "选中的值", index, value);
      this.show.showPicker_edu = false;
    },
    onConfirm_marray(value, index) {
      // console.log(value, "value");
      this.value.value_marray = {
        value,
        datavalue: this.indexs.index_marray[index],
      };

      console.log(this.value.value_marray, "选中的值", index, value);
      this.show.showPicker_marray = false;
    },
    onConfirm_childrens(value, index) {
      // console.log(value, "value");
      this.value.value_childrens = {
        value,
        datavalue: this.indexs.index_childrens[index],
      };

      console.log(this.value.value_childrens, "选中的值", index, value);
      this.show.showPicker_childrens = false;
    },
    onConfirm_work(value, index) {
      // console.log(value, "value");
      this.value.value_work = {
        value,
        datavalue: this.indexs.index_work[index],
      };

      console.log(this.value.value_work, "选中的值", index, value);
      this.show.showPicker_work = false;
    },
    onConfirm_source(value, index) {
      // console.log(value, "value");
      this.value.value_source = {
        value,
        datavalue: this.indexs.index_source[index],
      };

      console.log(this.value.value_source, "选中的值", index, value);
      this.show.showPicker_source = false;
    },
    handelCloseDia() {
      this.showDialog = false;
    },
    async handelDownload() {
      // 跳转到谷歌页
      const base =
        "https://play.google.com/store/apps/details?id=com.bilu.nuevasfinanzas";
      // const base = "https://www.baidu.com/";
      window.open(base, "_blank"); //打开新的页面
      const res = await hiddenSubmit({
        languageCode: "ESP",
        itemCode: "NUF",
        sessionId: this.sessionId,
      });
      console.log(res, "导流提交埋点");
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  // height: 1610px;
  background-image: url("~@/assets/write/background@2x.png");
  background-size: 100% 100%;
  padding-bottom: 48px;
  .title {
    // width: 710px;
    padding-top: 84px;
    padding-left: 40px;
    // background-color: red;
    margin-bottom: 203px;
    h1 {
      // width: 388px;
      // background-color: blue;
      color: rgba(255, 235, 121, 1);
      font-size: 60px;
      font-family: Roboto-Bold;
      font-weight: 700;
      text-align: left;
      line-height: 71px;
      margin-bottom: 12px;
      text-shadow: 0px 4px 14px rgba(18, 4, 255, 1);
    }
    .des {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 458px;
      height: 120px;
      font-family: Roboto-Regular, Roboto;
      margin-bottom: 203px;
      // background-color: pink;
      div {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #ffec62;
          border-radius: 50%;
          margin-right: 11px;
          vertical-align: middle;
        }
        p {
          // width: 407px;
          font-size: 28px;
          font-family: Roboto-Regular, Roboto;
          // font-weight: 400;
          color: #ffffff;
          line-height: 1;
          // margin: 0;
          text-align: left;
        }
      }
    }
  }
  .form {
    height: 1300px;
    margin: 0 30px;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 32px;

    .top {
      display: flex;
      height: 96px;
      justify-content: center;
      align-items: center;
      margin-bottom: 64px;
      span {
        display: inline-block;
        width: 96px;
        height: 96px;
        // background-color: pink;
        background-image: url("~@/assets/write/head@2x.png");
        background-size: 100% 100%;
        margin-right: 30px;
      }
      h4 {
        // width: 184px;
        // height: 38px;
        font-size: 32px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #333333;
        line-height: 38px;
      }
    }
    form {
      .main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // width: 100%;
        height: 932px;
        // background-color: blue;
        margin-bottom: 48px;
        padding: 0 24px;
        .item {
          // background-color: pink;
          position: relative;
          display: flex;
          align-items: flex-end;
          height: 122px;
          // background-color: red;
          ::v-deep .van-overlay {
            background-color: rgba(0, 0, 0, 0.2);
          }
          .van-cell {
            width: 612px;
            height: 80px;
            background: #f4f4ff;
            border-radius: 10px;

            font-size: 28px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #999999;
            line-height: 33px;
            // background-color: red;
            padding-left: 24px;
            overflow: visible;
            ::v-deep .van-field__label {
              position: absolute;
              top: -42px;
              left: 0px;
              line-height: 30px;
              width: 100%;
              height: 30px;
              font-size: 26px;
              color: #333333;
            }
            ::v-deep .van-cell__value {
              line-height: 50px;
            }
            // 设置placeholder字体颜色
            ::v-deep input.van-field__control::-webkit-input-placeholder {
              font-size: 24px;
              color: #999999;
              line-height: 33px;
            }
          }

          .van-cell::before {
            content: "";
            position: absolute;
            top: 24px;
            right: 0;
            // display: inline-block;
            width: 36px;
            height: 36px;
            // background-color: pink;
            background-image: url("~@/assets/write/list_icon_right@2x.png");
            background-size: 100% 100%;
            margin-right: 12px;
          }
        }

        .item_inp {
          position: relative;
          display: flex;
          align-items: flex-end;
          height: 122px;
          // background-color: red;
          ::v-deep .van-overlay {
            background-color: rgba(0, 0, 0, 0.2);
          }
          .van-cell {
            width: 612px;
            height: 80px;
            background: #f4f4ff;
            border-radius: 10px;

            font-size: 28px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #999999;
            line-height: 33px;
            // background-color: red;
            padding-left: 24px;
            overflow: visible;
            ::v-deep .van-field__label {
              position: absolute;
              top: -42px;
              left: 0px;
              line-height: 30px;
              width: 100%;
              height: 30px;
              font-size: 26px;
              color: #333333;
            }
            ::v-deep .van-cell__value {
              line-height: 50px;
            }
            // 设置placeholder字体颜色
            ::v-deep input.van-field__control::-webkit-input-placeholder {
              font-size: 24px;
              color: #999999;
              line-height: 33px;
            }
          }
        }
      }
      .submit {
        ::v-deep button {
          width: 612px;
          height: 80px;
          background: linear-gradient(180deg, #ffe689 0%, #ffbf47 100%);
          border-radius: 10px;

          font-size: 32px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #2922a8;
          line-height: 38px;
          border: none;
          margin: 0 auto;
        }
      }
    }
  }
  .van-dialog {
    display: flex;
    justify-content: center;
    top: 753px;
    // top: 253px;
    width: 624px;
    height: 600px;
    padding-bottom: 48px;
    overflow: visible;
    img {
      position: absolute;
      top: -110px;
      left: 202px;
      width: 220px;
      height: 220px;
      // margin-bottom: 18px;
    }
    p {
      width: 561px;
      height: 210px;
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;
      line-height: 42px;
      margin-top: 141px;
      margin-bottom: 48px;
    }
    button {
      width: 400px;
      height: 80px;
      background: linear-gradient(180deg, #ffe689 0%, #ffbf47 100%);
      border-radius: 10px;

      // width: 126px;
      // height: 45px;
      font-size: 32px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #2922a8;
      line-height: 38px;
      margin-bottom: 28px;
    }
    .wait {
      // display: block;
      width: 100%;
      // height: 45px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 45px;
      text-align: center;
    }
  }
}
@media screen and (max-heigth: 365px) {
}
</style>
