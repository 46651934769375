<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
const defaultSettings = require("../src/settings.js");

const name = defaultSettings.title || "vue Element Admin"; // page title
export default {
  async created() {
    document.title = name; //通过设置document.title可以将标题覆盖
  },
};
</script>

<style lang="less">
.van-toast.van-toast--middle {
  width: 350px !important;
}
.box {
  width: 200px;
  height: 200px;
  background-color: pink;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
