/* 封装axios用于发送请求 */
import axios from "axios";
import { Toast } from "vant";
// 创建一个新的axios实例
const request = axios.create({
  // baseURL: "http://127.0.0.1:403",
  // 测试环境
  // baseURL: "http://47.242.148.57:8501",
  // 生产环境
  baseURL: "https://api.nuevasfinanzass.com",

  // baseURL: "/",
  timeout: 5000,
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // config.headers = {
    //   "Content-Type": "application/json; charset=utf-8",
    // }
    // config.headers.isSecert = 0;
    console.log(config);
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    if (error.response) {
      Toast.fail(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

export default request;
