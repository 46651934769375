import Vue from "vue";
import VueRouter from "vue-router";

import SubmitInfo from "@/views/submitInfo.vue";
import Register from "@/views/register.vue";
// import Layout from '@/views/layout.vue'
// import Detail from '@/views/detail.vue'

// import Article from '@/views/article.vue'
// import Collect from '@/views/collect.vue'
// import Like from '@/views/like.vue'
// import User from '@/views/user.vue'

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: "/", redirect: "/register" },
    // ===============规则里path和component是匹配关系，如path为/时，可以匹配到Layout
    // {
    //   path: '/',
    //   component: Layout,
    //   redirect: '/article',
    //   children: [
    //     { path: '/article', component: Article },
    //     { path: '/collect', component: Collect },
    //     { path: '/like', component: Like },
    //     { path: '/user', component: User }
    //   ]
    // },
    { path: "/submitInfo", component: SubmitInfo },
    { path: "/register", component: Register },
    // { path: '/detali/:id', component: Detail }
  ],
});

export default router;
