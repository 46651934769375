var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-page"},[_vm._m(0),_c('van-form',{ref:"form",on:{"failed":_vm.onFailed}},[_c('div',{staticClass:"inp"},[_c('span',[_vm._v("+51")]),_c('van-field',{attrs:{"maxlength":"9","type":"number","name":"mobile","placeholder":"Por favor, introduzca su número de teléfono móvil","rules":[
          { required: true, trigger: 'onBlur' },
          { validator: _vm.validator, trigger: 'onBlur' },
        ]},model:{value:(_vm.registerForm.mobile),callback:function ($$v) {_vm.$set(_vm.registerForm, "mobile", $$v)},expression:"registerForm.mobile"}})],1),_c('p',[_vm._v("Example:912345678 (9 digits)")]),_c('div',{staticClass:"send"},[_c('van-field',{attrs:{"maxlength":"4","center":"","clearable":"","type":"number","placeholder":"Código de verificación de 4 dígitos","rules":[
          { required: true, trigger: 'onBlur' },
          { pattern: /^[0-9]{4}$/, trigger: 'onBlur' },
        ]},model:{value:(_vm.registerForm.code),callback:function ($$v) {_vm.$set(_vm.registerForm, "code", $$v)},expression:"registerForm.code"}}),_c('van-button',{ref:"btn",class:{ line_heigth: _vm.lineHeigth },attrs:{"size":"large","type":"primary","disabled":_vm.btnDisabled,"text":_vm.getCodeText},on:{"click":_vm.handelBtnClick}})],1),_c('div',{staticClass:"submit"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"},on:{"click":_vm.handelSub}},[_vm._v("Ver la cuota no auditoría")])],1)]),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("Hasta S/ 2000")]),_c('div',{staticClass:"des"},[_c('div',[_c('span'),_c('p',[_vm._v("1 minuto para rellenar sus datos")])]),_c('div',[_c('span'),_c('p',[_vm._v("aprobar en 1 minuto")])]),_c('div',[_c('span'),_c('p',[_vm._v("pagar en 5 minutos")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"sub_title"},[_c('span'),_c('h3',[_vm._v("Por qué elegir Fondos Ricos")]),_c('span')]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/icon_interest@2x.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h5',[_vm._v("Tipo de interés bajo")]),_c('p',[_vm._v("Tipos de interés anualizados mínimo 6%")])])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/icon_operation@2x.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h5',[_vm._v("Fácil de manejar")]),_c('p',[_vm._v("Aplicación rápida en 4 pasos")])])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/icon_fast@2x.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h5',[_vm._v("Pago rápido")]),_c('p',[_vm._v("Se llega en 15 minutos a su cuenta")])])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/icon_high@2x.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('h5',[_vm._v("Límite de crédito elevado")]),_c('p',[_vm._v("Préstamo de hasta S/ 2000")])])])])])
}]

export { render, staticRenderFns }